/*
  Consider to clean up, utilizie some CSS inheritance etc. etc. 
*/

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@import url("https://fonts.googleapis.com/css?family=Oxygen+Mono|Roboto+Mono|Cormorant+Garamond|EB+Garamond|Libre+Baskerville|Merriweather|Tinos&display=swap");

body {
  color: #566970;
  background-color: white;
  font-family: "Roboto Mono", "Roboto", "Oxygen Mono", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Helvetica Neue", sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 120px;
  overflow-y: scroll;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  float: left;
  padding: 16px;
  text-decoration: none;
}

h1 {
  font-family: "Helvetica";
  font-size: 1.9em;
  line-height: 0.7em;
}

h2 {
  font-size: 1.6em;
  font-weight: normal;
  text-rendering: geometricPrecision;
}

h1,
h2,
h3 {
  color: navy;
}

h2,
h3 {
  font-family: "Roboto Mono";
}

a {
  color: #566970;
  text-decoration: none;
}

a:hover {
  color: darkorange;
}

.navStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.navActive {
  color: navy;
  text-decoration: none;
}

.textStyle {
  padding: 25px;
  max-width: 750px;
  margin: auto;
  text-align: justify;
  line-height: 2;
}

.banner {
  padding: 25px 25;
  max-width: 750px;
  max-height: 65px;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1px;
}

.footer {
  border-top: 3px solid navy;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: white;
  text-align: center;
}

.social {
  margin: 15px;
  color: navy;
}

.social:hover {
  color: darkorange;
}

.widgetStyle {
  font-size: small;
}

#container {
  width: 100%;
}

#right {
  float: right;
  width: 100px;
}

#center {
  margin: 0 auto;
  width: 200px;
}

.contactLayout {
  color: navy;
  line-height: 2em;
  font-size: small;
  justify-content: center;
  position: absolute;
  padding-bottom: 100px;
}

input {
  width: 25%;
  outline: none;
  border: 1px solid navy;
  width: 200px;
  height: 15px;
  padding: 3px;
  font-family: "Roboto Mono", "Helvetica";
  font-size: small;
}

textArea {
  resize: none;
  outline: none;
  border: 1px solid navy;
  padding: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 90%;
  max-width: 850px;
  font-family: "Roboto Mono", "Helvetica";
  font-size: small;
}

.buttonStyle {
  font-family: "Roboto Mono", "Helvetica";
  background-color: navy;
  border: none;
  color: white;
  padding: 10px 25px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  outline: none;
}

.buttonStyle:hover {
  background-color: darkorange;
}

.errStyle {
  color: red;
}

.boxProfile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid 3px;
  color: navy;
}

.roundProfile {
  border-radius: 50%;
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid 3px;
  color: navy;
}

.Toastify__toast-body {
  font-family: "Roboto Mono";
  color: #566970;
}
